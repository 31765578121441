@import '../styles/variaveis';

#wrapper {
    width: calc(100% - 200px);
    margin-left: 200px;
    margin-top: 0px;
    transition: all 0.5s;
    
    .page-container {
        padding: 0px;
    }
}

.wrapper-container-content {
    //display: flex;
    background-color: white;
    min-height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right bottom;
    background-image: url(../assets/img/bg-wave.svg);
}

.wrapper-container-contentAdmin {
    background-color: #fff;
    min-height: 100vh;
    width: calc(100% - 270px);
}


.wrapper-page-content {
    position: relative;
    // background-color: blue;
    flex: 1;
    padding: 0px 20px 60px 20px;
    &.bgwhite {
        background-color: white;
    }
}
.wrapper-page-content-interna {
    padding: 10px;
    position: relative;
}

#dragbar{
    background-color:transparent;
    height: 100%;
    width: 3px;
    cursor: col-resize;
    position: absolute;
    right: 0px;
}

.wrapper-container-submenuAdmin.hide {
    #dragbar{
       display: none;
    }
}



@media screen and (max-width: $brackpoint-md) {
    .wrapper-container-contentAdmin {
        width: calc(100%);
        min-height: inherit;
    }

    body {
        &.m-l-o {
            #wrapper {
                width: 100%;
                margin-left: 0px;
            }
        }
    }
    #wrapper {
        width: 100% ;
        margin-left: 0px;
        
        .page-container {
            padding: 0px 0px 0px 0px;
        }
    }
    .wrapper-page-content {
        padding: 70px 20px 20px 20px;
    }

    #dragbar{
        display: none;
    }

}
