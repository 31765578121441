@import '../../styles/variaveis';

.menu-title-list {
  padding-top: 40px;
  padding-bottom: 20px;
}

.system-menu-sidebar {
  width: 200px;
  top: 0px;
  left: 0px;
  opacity: 1;
  
  .system-menu-sidebar_titleMenu_close {
    display: none;
    //display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0px;
    padding: 20px 20px 10px 20px;

    span {
      cursor: pointer;
    }
  }
  .system-menu-sidebar_titleMenu {}

  ul {
    padding: 0px;
    list-style: none;
    margin: 12px 0px 12px 0px;
    display: flex;
    flex-direction: column;
    gap: 2px;

    button {
      cursor: pointer;
      width: 100%;
      background-color: #D4D4D4;
      color: #5A5A5A;
      border: none;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 8px;
      border-radius: 0px;
      padding: 8px 12px;

      span {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 8px;
      }

      &:hover {
        color: white;
        background-color: $cor-primary;
      }
    }

    li {
      
      &.divider {
        margin: 8px 0px;
        border-bottom: 1px solid #dee9f9;
      }

      &:hover {
        color: white;
        a {
          color: white;
          background-color: $cor-primary;
          
        }
        ul > li,
        ul > li > a {
          color: #575756;
        }
      }

      a,
      &.link {
        cursor: pointer;
        background-color: #D4D4D4;
        color: #5A5A5A;
        display: flex;
        align-items: center;
        gap: 8px;
        font-weight: 500;
        font-size: 14px;
        text-decoration: none;
        border-radius: 0px;
        padding: 8px 12px;
      }

      &.active {
        color: white;

        .btn-noStile {
          color: $cor-secundary-2;
          background-color: white;
          box-shadow: 0px 0px 6px darken($cor-cold-white, 2%);
          border-radius: 0px;
          padding: 8px 12px;
          font-weight: bold;
        }

        a {
          font-weight: bold;
          color: white;
          background-color: $cor-primary;
        }
        
        ul > li {
          &.active {
            a {
              color: $cor-secundary-2;
              box-shadow: none;
              font-weight: bold;
              background-color: $cor-primary-dark;
            }
          }
        }
        ul > li,
        ul > li > a {
          background-color: transparent;
          box-shadow: none;
          color: #575756;
        }
      }

      ul {
        max-height: 0;
        overflow: hidden;
        opacity: 0;
        transition: all 0.2s;
        margin: 0;
        border: 0px solid transparent;
        padding: 0px;

        li {
          color: #575756;

          &:hover {
            color: black;
          }

          a,
          &.link {
            cursor: pointer;
            display: flex;
            align-items: center;
            gap: 8px;
            padding: 6px 8px;
            font-weight: 500;
            font-size: 14px;
            color: $cor-gray-8;
            text-decoration: none;
          }

          &:active,
          &.active {
            color: $cor-secundary-2;
          }

          a:hover {
            color: $cor-secundary-2;
          }

          ul {
            li {
              a:hover {
                color: $cor-secundary-2;
              }
              &:active,
              &.active {
                a {
                  background-color: transparent;
                  box-shadow: none;
                  font-weight: bold;
                }
              }
            }
          }
        }
      }

      .subMenu-icon-arrow {
        transition: all 0.2s;
      }

      &.show {
        button {
          // background-color: white;
          // color: $cor-secundary-2;
        }
        .subMenu-icon-arrow {
          transform: rotate(90deg);
        }
        ul {
          opacity: 1;
          max-height: 500px;
          transition: all 0.5s;
          left: 200px;
          padding: 0px;
          display: block;
          li {
            a {
              background-color: white;
              font-weight: 13px;
              padding: 10px 16px;
              border-bottom: 1px solid #f4f4f4;
            }
          }
        }
      }
    }
  }
}

.system-menu-sidebar-backdrop {
  background-color: unset;
  width: 0px;
  height: 100vh;
  position: fixed;
  z-index: 7;
  transition: opacity 0.2s;
  opacity: 0;
  top:0px;
}

body {
  &.m-l-o {
    .system-menu-sidebar-backdrop {
      width:100%;
    }
    .system-menu-sidebar {
      left: 64px;
      opacity: 1;
      z-index: 10;
      ul {
        li {
          ul {
            left: 200px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $brackpoint-md) {
  .system-menu-sidebar ul li.active .btn-noStile {
    border-radius: 0px;
  }

  .system-menu-sidebar {
    left: 0px;
    opacity: 0;

    z-index: 8;
    height: 0;
    overflow: hidden; 
    top: -10px;
    opacity: 1;
    width: 100%;
    background-color: #fff;

    .system-menu-sidebar_titleMenu_close {
      display: flex;
      span {
        cursor: pointer;
      }
    }
    .system-menu-sidebar_titleMenu { display: none; }

    
    .menu-title-list {
      display: none;
    }
  }

  body {
    &.m-l-o {
      .system-menu-sidebar {
        left: 0px;
        opacity: 0;
      }
    }

    &.m-s-o {
      overflow-y: unset;

      .system-menu-sidebar {
        position: fixed;
        top: 0px;
        left: 0px;
        opacity: 1;
        z-index: 8;
        width: 100%;
        height: 100vh;
        background-color: #fff;

        ul {
          margin:10px 0px;
          button {
            border-bottom: 1px solid #e1e1e1;
              border-radius: 0px;
          }
          
          li {
            &:first-child {
              padding-top: 0px
            }
            a {
              border-bottom: 1px solid #e1e1e1;
              border-radius: 0px;
            }
            ul {
              margin:0px;
              border-right: none;  
              position: relative; 
              left: unset;
              height: 0;
              padding: 0px;
              min-height: unset;
              max-height: 0;
              li {
                a {
                  border-bottom: none;
                }
              }
            }
            &.show {
              ul {
                opacity: 1;
                height: auto;
                max-height: 500px;
                transition: all 0.5s;
                margin: 8px 20px;
              }
            }
          }
        }
      }

      .system-menu-sidebar-backdrop {
        background-color: #00000050;
        width: 100%;
        height: 100vh;
        position: fixed;
        z-index: 7;
        opacity: 1;
      }
    }
  }
}

@media screen and (max-width: $brackpoint-sm) {
  body {
    &.m-s-o {
      .system-menu-sidebar {
        left: 0px;
        width: 100%;
        height: auto;
        opacity: 1;
        z-index: 8;
      }
    }
  }
}
