@import 'variaveis';

.MuiButton-root.MuiButton-containedSecondary {
  text-transform: unset !important;
  background-color: $cor-primary !important;
}
.MuiButton-root.MuiButton-text {
  color: rgb(51, 51, 51);
  font-weight: normal;
  text-transform: unset;
  opacity: 0.6;
}
.MuiBackdrop-root.backdrop_main {
  z-index: 99999 !important;
  color: #fff !important;
}

.MuiAutocomplete-input.MuiInputBase-inputSizeSmall {
  padding: 0px 6px 2px 6px !important;
}

.MuiFormControl-root {
  width: 100%;
}
.btn-rounded {
  .MuiInputBase-root {
    border-radius: 50px !important;
    .MuiInputBase-input.MuiOutlinedInput-input {
      border-radius: 50px !important;
    }
  }
}
.btn-rounded.MuiInputBase-root {
  border-radius: 50px !important;
  .MuiSelect-select {
    border-radius: 50px !important;
  }
}
.MuiFormLabel-root-MuiInputLabel-root, .MuiInputLabel-root {
  font-size: 14px !important;
}
.MuiFormControl-root {
  .MuiInputLabel-root {
    transform: translate(14px, 9px) scale(1)
  }
  .MuiInputLabel-shrink {
    transform: translate(14px, -7px) scale(0.75)
  }
}
.MuiInputBase-root {
  background-color: white;

  .MuiInputBase-input.MuiOutlinedInput-input {
    background-color: white;
    padding: 7px 14px;
    &:hover {
      background-color: white;
    }
    &.Mui-disabled {
      &:hover {
        background-color: white;
      }
    }
  }
  
  &:hover {
    background-color: white;
  }
  

  &.Mui-disabled {
    &:hover {
      background-color: white;
    }
  }
  
}

.SnackbarContent-root {
  display: flex !important;
  flex-wrap: nowrap !important;
}

.SnackbarItem-variantError {
  .MuiSvgIcon-root {
    color: white
  }
}