@import '../../styles/variaveis.scss';

.MuiPaper-rounded {
  border-radius: 14px !important;
}

#form-dialog-title {
  padding-right: 50px;
  font-weight: bold !important;
  text-align: center;
  // background-color: $cor-primary;
  // color: white;
  border-radius: 4px;

  p {
    font-weight: bold !important;
  }
}

