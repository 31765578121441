@import '../../styles/variaveis';

.appCard_Item {
  border: 1px solid $cor-primary;
  border-radius: 10px;
  background-size: contain;
  background-position: top center;
  background-repeat: no-repeat;
  background-image: url('../../assets/img/default_picture.png');

  .appCard_Item_Content {
    background: linear-gradient(0deg, #ffffff 0%, #f4f4f4 100%);
    border-top: 1px solid $cor-primary;
    padding: 14px;
    border-radius: 0 0 10px 10px;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  .appCard_Item_Imagem {
    padding: 0px;
    border-radius: 10px 10px 0 0;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

    img {
      width: 100%;
      margin: 0px;
      padding: 0px;
      border-radius: 10px 10px 0 0;
    }
  }
}

@media screen and (max-width: $brackpoint-xs) {

}
