@import '../../styles/variaveis';

.loadPage {
  z-index: 9999;
  position: fixed;
  width: 100%;
  top: 0px;
  left: 0px;
  height: 5px;
}
