@import '../../styles/variaveis';

.loading {
  .circleLoading {
    height: 20vh;

    ellipse {
      animation: loadingLogo 2s ease-in infinite;
    }

    @mixin animation-mixin($name, $from, $to) {
      @keyframes #{$name} {
        0% {
          transform-origin: center;
          transform: scale(0.8);
        }
        50% {
          transform-origin: center;
          transform: scale(1);
        }
        100% {
          transform-origin: center;
          transform: scale(0.8);
        }
      }
    }

    @include animation-mixin(loadingLogo, 0, 100%);
  }

  .squareLoading {
    height: 12vh;

    #loading {
      animation: loadingLogo 2s ease-in infinite;
    }

    @mixin animation-mixin($name, $from, $to) {
      @keyframes #{$name} {
        0% {
          transform-origin: center;
          transform: scale(0.8);
        }
        50% {
          transform-origin: center;
          transform: scale(1);
        }
        100% {
          transform-origin: center;
          transform: scale(0.8);
        }
      }
    }

    @include animation-mixin(loadingLogo, 0, 100%);
  }

  .standardLoading {
    .logo {
      height: 4rem;
      width: 7rem;
    }

    .gear {
      height: 2rem;
      position: absolute;
      animation: gearSpinning 2s linear infinite;
    }

    @mixin animation-mixin($name, $from, $to) {
      @keyframes #{$name} {
        0% {
          transform: translate(-1rem, -1rem);
        }
        25% {
          transform: translate(6rem, -1rem);
        }
        50% {
          transform: translate(6rem, 3rem);
        }
        75% {
          transform: translate(-1rem, 3rem);
        }
        100% {
          transform: translate(-1rem, -1rem);
        }
      }
    }

    @include animation-mixin(gearSpinning, 0, 100%);
  }
}
