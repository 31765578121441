$cor-primary: #E21AE7;
$cor-primary-dark: #7b1fa2;
$cor-secundary: #00e6ff;
$cor-secundary-2: #d614e1;


$cor-cold-white: #f1f7ff;

$cor-gray: #6d7787;
$cor-gray-8: #575756;
$cor-gray-5: #9d9d9c;
$cor-gray-3: #c6c6c6;
$cor-gray-1: #f1f1f2;

$cor-success: #739a00;
$cor-warning: #ffcf21;
$cor-danger: #f72717;
$cor-link: #0095db;

$sidebar-menu-size: 240px;
$sidebar-menu-warper: calc(100% - 240px);
$header-min-height: 60px;

$font-regular: 'Nunito Sans', 'Lucida Sans', 'Lucida Sans Regular', Verdana,
  sans-serif;
$font-title: 'Nunito Sans', 'Lucida Sans', 'Lucida Sans Regular', Verdana,
  sans-serif;

// breakpoints
$brackpoint-xs: 370px;
$brackpoint-sm: 576px;
$brackpoint-md: 768px;
$brackpoint-lg: 992px;
$brackpoint-xl: 1200px;
$brackpoint-2xl: 1536px;
